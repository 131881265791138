import React, { useEffect, useState } from 'react';
import { TextField, Button, Typography, Box, CircularProgress, ToggleButtonGroup, ToggleButton, Stack } from '@mui/material';
import styled from '@emotion/styled';

interface ApiResponse {
  emoji: string;
  emojiName: string;
  isAgency: boolean;
  isEcommerce: boolean;
  keywords: Array<{ weight: number; word: string }>;
  mainVertical: string;
  secondaryVertical: string;
  title?: string;
  description?: string;
}

const Keyword = styled.span<{ weight: number }>`
  font-size: ${({ weight }) => weight * 2 + 1}rem;
`;

const Code = styled.pre({
  backgroundColor: '#eee',
  padding: '1rem',
  borderRadius: '0.5rem',
  overflow: 'auto',
  whiteSpace: 'pre-wrap',
  wordWrap: 'break-word',
  fontFamily: 'monospace',
  fontSize: '1rem',
});

const App: React.FC = () => {
  const [url, setUrl] = useState('cafeleather.com');
  const [response, setResponse] = useState<ApiResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedView, setSelectedView] = useState<'description' | 'json'>('description');

  const handleSearch = async () => {
    setLoading(true);
    const res = await fetch(`https://site-vertical.sejas.es/infer`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ url }),
    });
    const data: ApiResponse = await res.json();
    setResponse(data);
    setLoading(false);
  };

  const handleViewChange = (
    event: React.MouseEvent<HTMLElement>,
    newView: 'description' | 'json',
  ) => {
    setSelectedView(newView);
  };

  useEffect(() => {
    setResponse({"description":"Caf\u00e9 Leather is a premium leather goods brand based in Madrid, Spain. We manufacture every product by hand in Spain.","emoji":"\ud83d\udc5c", "emojiName": 'handbag',"isAgency":false,"isEcommerce":true,"keywords":[{"weight":0.5,"word":"leather"},{"weight":0.3,"word":"Spain"},{"weight":0.2,"word":"handmade"}],"mainVertical":"fashion","secondaryVertical":"accessories","title":"Caf\u00e9 Leather | Premium Leather Goods | Handmade in Spain"}
    );
  }, [url]);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        p: 4,
      }}
    >
      <Typography variant="h4" gutterBottom>
        Site Vertical
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", width: "100%", mb: 4 }}
      >
        <TextField
          label="URL"
          variant="outlined"
          value={url}
          onChange={(e) => setUrl(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              handleSearch();
            }
          }}
          fullWidth
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSearch}
          sx={{ ml: 2 }}
          disabled={loading}
        >
          Search
        </Button>
      </Box>
      <Box sx={{ mb: 2 }}>
        <ToggleButtonGroup
          value={selectedView}
          exclusive
          onChange={handleViewChange}
          aria-label="view toggle"
        >
          <ToggleButton value="description" aria-label="description">
            Description
          </ToggleButton>
          <ToggleButton value="json" aria-label="json">
            JSON
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
      {response && !loading &&
        (selectedView === "description" ? (
          <Stack spacing={4}>
            <Typography variant="h3" sx={{ textTransform: "capitalize" }}>
              <span title={response.emojiName}>{response.emoji}</span>{" "}
              <span>{response.mainVertical}</span>{" "}
              <span style={{ fontSize: "0.5em" }}>
                ({response.secondaryVertical})
              </span>
            </Typography>
            <Typography variant="h4">
              Is Agency? {response.isAgency ? "✅" : "❌"}
            </Typography>
            <Typography variant="h4">
              Is E-commerce: {response.isEcommerce ? "✅" : "❌"}
            </Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap", alignItems: "center" }}>
              <Typography variant="h4">Keywords:</Typography>
              {response.keywords.map((keyword, index) => (
                <Box key={index} sx={{ m: 1 }}>
                  <Keyword weight={keyword.weight*1.5}>{keyword.word}</Keyword>
                </Box>
              ))}
            </Box>
          </Stack>
        ) : (
          <Code>{JSON.stringify(response, null, 2)}</Code>
        ))}
      {loading && (
        <Typography variant="h6" sx={{ mt: 2 }}>
          <CircularProgress color="secondary" />
        </Typography>
      )}
    </Box>
  );
};

export default App;
